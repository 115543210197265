<template>
  <!-- BODY -->
  <b-modal
    id="add-new-user-sidebar"
    ref="sideBar"
    size="lg"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Cadastro de Promoção"
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    ok-title="Salvar"
    cancel-title="Cancelar"
    @change="(val) => $emit('update:is-active', val)"
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <template #header="{hide}" >
      <div class="d-flex justify-content-between align-items-center w-100" >
        <h5 class="mb-0">
          Cadastro de Promoção
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>

    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder=""
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.series"
                rules="required"
                label="Séries"
                placeholder=""
                name="serie"
                @input="changeTotalNumbers()"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.numbers"
                :disabled="true"
                rules="required"
                label="Números por série"
                placeholder=""
                name="numbers"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="4"
              md="4"
            >
              <i2-form-input
                v-model="model.total_numbers"
                rules="required"
                label="Total de números da promoção"
                placeholder=""
                name="total_numbers"
                :disabled="true"
              />
            </b-col>
            <b-col
              sm="4"
              md="4"
            >
              <i2-form-input
                v-model="model.authorization_code"
                rules="required"
                label="Código de Autorização"
                placeholder=""
                name="authorization_code"
              />
            </b-col>
            <b-col
              sm="4"
              md="4"
            >
              <i2-form-input
                v-model="model.type"
                rules="required"
                label="Tipo da Promoção"
                placeholder=""
                name="type"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.extract_numbers"
                label="Extrair Números"
                :options="extract_numbers_available"
                rules="required"
                placeholder=""
                name="extract_numbers"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.series_digits"
                rules="required"
                label="Dígitos da Série"
                placeholder=""
                name="series_digits"
                :disabled="false"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-vue-select
                v-model="model.generate_number_on_period"
                label="Número Somente no Período?"
                :options="booleanOptions"
                rules="required"
                placeholder=""
                name="generate_number_on_period"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-vue-select
                v-model="model.allow_more_numbers_on_prize_draw"
                label="Permitir mais de um número?"
                :options="booleanOptions"
                rules="required"
                placeholder=""
                name="allow_more_numbers_on_prize_draw"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.max_luck_numbers_participant"
                label="Total Máximo de números por Participante"
                type="number"
                :disabled="!model.allow_more_numbers_on_prize_draw.value"
                placeholder=""
                name="max_luck_numbers_participant"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-vue-select
                v-model="model.allow_same_winner"
                label="Permitir mesmo vencedor no sorteio?"
                :options="booleanOptions"
                rules="required"
                placeholder=""
                name="allow_same_winner"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-vue-select
                v-model="model.numbers_valid_for"
                label="Números valido para:"
                :options="numbers_valid_for_available"
                rules="required"
                placeholder=""
                name="numbers_valid_for"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.winners"
                rules="required"
                label="Vencedores:"
                placeholder=""
                name="winners"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-vue-select
                v-model="model.unsubscription_by"
                label="Desistência:"
                :options="unsubscription_by_available"
                rules="required"
                placeholder=""
                name="unsubscription_by"
              />
            </b-col>
          </b-row>
          <b-row class="align-items-center items-align-center">
            <b-col
              sm="6"
              md="6"
            >
              <b-form-checkbox
                v-model="model.can_import_file"
                :checked="model.can_import_file"
                name="can_import_file"
                switch
                inline
                @change="changePromotionType()"
              >
                Permite Importar Arquivo
              </b-form-checkbox>
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-vue-select
                v-model="promotion_type_selected"
                :disabled="model.can_import_file"
                label="Tipo de Promoção:"
                :options="promotion_types"
                placeholder=""
                name="promotion_type"
                @input="promotionTypeSelected"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <b-form-checkbox
                v-model="model.process_in_background"
                :checked="model.process_in_background"
                name="process_in_background"
                switch
                inline
                @change="changePromotionType()"
              >
                Processar o sorteio em segundo plano?
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>

      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'PromotionForm',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
  },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      extract_numbers_available: [],
      numbers_valid_for_available: [],
      unsubscription_by_available: [],
      booleanOptions: [],
      promotion_type_selected: '',
      model: {
        name: '',
        uuid: null,
        series: null,
        series_digits: null,
        numbers: 100000,
        total_numbers: 0,
        winners: 1,
        max_luck_numbers_participant: null,
        is_instagram_promotion: false,
        is_coupon_promotion: false,
        promotion_type: '',
        authorization_code: '',
        type: 'Assemelhado a sorteio',
        can_import_file: false,
        process_in_background: false,
        extract_numbers: {
          value: '',
          text: '',
        },
        generate_number_on_period: {
          value: true,
          text: 'Sim',
        },
        allow_more_numbers_on_prize_draw: {
          value: true,
          text: 'Sim',
        },
        allow_same_winner: {
          value: false,
          text: 'Não',
        },
        numbers_valid_for: {
          value: '',
          text: '',
        },
        unsubscription_by: {
          value: '',
          text: '',
        },
      },
      promotion_types: [
        {
          value: 'instagram',
          text: 'Por Instagram',
        },
        {
          value: 'coupon',
          text: 'Por Cupons',
        },
      ],
    }
  },
  watch: {
    promotion(val) {
      if (val.uuid) {
        this.getData(val.uuid)
      }
    },
  },
  async mounted() {
    await this.getExtractNumbers()
    await this.getNumbersValidFor()
    await this.getUnsubscriptionsBy()
    this.booleanOptions = [
      {
        value: true,
        text: 'Sim',
      },
      {
        value: false,
        text: 'Não',
      },
    ]
  },
  methods: {
    async getExtractNumbers() {
      this.extract_numbers_available = await this.$http.get('/configuration/extract-numbers')
    },
    async getNumbersValidFor() {
      this.numbers_valid_for_available = await this.$http.get('/configuration/numbers-valid-for')
    },
    async getUnsubscriptionsBy() {
      this.unsubscription_by_available = await this.$http.get('/configuration/unsubscription-by')
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          data.generate_number_on_period = this.model.generate_number_on_period.value
          data.allow_more_numbers_on_prize_draw = this.model.allow_more_numbers_on_prize_draw.value
          data.extract_numbers_rule = this.model.extract_numbers.value
          data.number_valid_for = this.model.numbers_valid_for.value
          data.unsubscription_by = this.model.unsubscription_by.value
          data.is_instagram_promotion = this.model.is_instagram_promotion
          data.is_coupon_promotion = this.model.is_coupon_promotion
          data.can_import_file = this.model.can_import_file
          data.allow_same_winner = this.model.allow_same_winner.value
          data.series_digits = this.model.series_digits
          data.type = this.model.type
          data.authorization_code = this.model.authorization_code
          data.process_in_background = this.model.process_in_background
          if (this.model.uuid) {
            response = await this.$http.put(`promotions/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('promotions', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Promoção salva com sucesso.')
          this.resetModel()
          this.$refs.refFormObserver.reset()
          this.$emit('onModelSave', true)
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`promotions/${uuid}`)
      this.model = {
        ...response,
        winners: response.settings.total_winners,
        extract_numbers: this.extract_numbers_available.find(val => val.value === response.extract_type),
        generate_number_on_period: this.booleanOptions.find(val => val.value === response.settings.generate_number_on_period),
        allow_more_numbers_on_prize_draw: this.booleanOptions.find(val => val.value === response.settings.allow_more_numbers_on_prize_draw),
        numbers_valid_for: this.numbers_valid_for_available.find(val => val.value === response.number_valid_for),
        unsubscription_by: this.unsubscription_by_available.find(val => val.value === response.settings.unsubscription_by),
        is_instagram_promotion: response.settings.is_instagram_promotion,
        is_coupon_promotion: response.settings.is_coupon_promotion || false,
        promotion_type: response.settings.promotion_type || '',
        can_import_file: response.settings.can_import_file,
        series_digits: response.series_digits,
        allow_same_winner: this.booleanOptions.find(val => val.value === response.settings.allow_same_winner),
        process_in_background: response.process_in_background,
      }

      // eslint-disable-next-line array-callback-return
      this.promotion_type_selected = this.promotion_types.map(type => {
        if (type.value === response.settings.promotion_type) {
          return type
        }
        return ''
      })
      this.changeTotalNumbers()
    },
    resetModel() {
      this.model = {
        name: '',
        uuid: null,
        series: null,
        numbers: null,
        total_numbers: null,
        max_luck_numbers_participant: null,
        is_instagram_promotion: false,
        is_coupon_promotion: false,
        can_import_file: false,
        is_allowed_search_number: true,
        series_digits: null,
        winners: 1,
        extract_numbers: {
          value: '',
          text: '',
        },
        generate_number_on_period: {
          value: true,
          text: 'Sim',
        },
        allow_more_numbers_on_prize_draw: {
          value: true,
          text: 'Sim',
        },
        allow_same_winner: {
          value: false,
          text: 'Não',
        },
        numbers_valid_for: this.unsubscription_by_available[0],
      }
    },
    promotionTypeSelected(promotion) {
      this.model.promotion_type = promotion.value
      this.model.is_coupon_promotion = promotion.value === 'coupon'
      this.model.is_instagram_promotion = promotion.value === 'instagram'
    },
    changePromotionType() {
      this.model.promotion_type = ''
      this.promotion_type_selected = ''
      this.model.is_coupon_promotion = false
      this.model.is_instagram_promotion = false
    },
    changeTotalNumbers() {
      this.model.total_numbers = (new Intl.NumberFormat('pt-BR').format((this.model.series * this.model.numbers)))
    },
  },
}
</script>

<style scoped>

</style>
