const userTypes = [
  {
    value: 'super_admin',
    text: 'Super Administrador',
  },
  {
    value: 'admin',
    text: 'Administrador',
  },
  {
    value: 'user',
    text: 'Usuário',
  },
  {
    value: 'secretary',
    text: 'Atendente',
  },

]
export default userTypes
