<template>
  <b-modal
    v-model="showModal"
    modal-class="modal-danger"
    no-close-on-backdrop
    hide-header-close
    title="Confirma o arquivamento da promoção?"
    cancel-title="Fechar"
    ok-title="Sim, eu confirmo!"
    ok-variant="danger"
    size="md"
    @change="onHide"
    @ok="onConfirm"
  >
    <p>Ao arquivar a promoção <b>{{ currentPromotion.name }}</b>, os dados serão removidos do banco de dados e não será possível acessar mais esta promoção através da plataforma.</p>
    <p><b>Atenção!</b><br />Este procedimento pode demorar alguns minutos. </p>
    <p>Tem certeza que deseja continuar com o arquivamento?</p>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'PromotionArchiveConfirmationModal',
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      currentPromotion: {
        name: '',
      },
    }
  },
  watch: {
    show(value) {
      this.showModal = value
    },
    promotion(value) {
      this.currentPromotion = value
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions('auth', ['me']),
    onHide() {
      this.$emit('onClose', true)
    },
    async onConfirm(bvModal) {
      bvModal.preventDefault()
      const response = await this.$http.post(`promotions/${this.currentPromotion.uuid}/archive`)
      this.$notify.success('A promoção está sendo arquivada. Ao terminar o processamento, você será notificado através de seu e-mail.')
      await this.me()
      this.onHide()
    },
  },
}
</script>

<style scoped>

</style>
