<template>
  <b-card-code>
    <page-header
      name="Promoções"
    >
      <b-button
        variant="primary"
        @click="newPromotion"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <promotion-form
      :promotion="currentPromotion"
      :is-active.sync="isActivated"
      @onModelSave="onPromotionSave()"
      @onCancel="onCancelEdit()"
    />

    <promotion-archive-confirmation-modal
      :promotion="currentPromotionArchive"
      :show="openArchivePromotionModal"
      @onClose="closeArchivePromotionModal"
    />

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template #cell(information)="data">
        <b>ID:</b> {{ data.item.uuid }}<br>
        <b>Nome:</b> {{ data.item.name }}<br>
        <b>Série / Número:</b> {{ data.item.series }} / {{ data.item.numbers }}<br>
        <b-badge
          v-if="data.item.is_archived"
          variant="info"
          class="d-block"
        >
          Promoção Arquivada
        </b-badge>
      </template>
      <template #cell(configuration)="data">
        <b>Extrair Números:</b> {{ data.item.extract_type }}<br>
        <b>Gerar Número Somente no Período?</b> {{ data.item.settings.generate_number_on_period ? 'Sim' : 'Não' }}<br>
        <b>Permitir mais de um número por participante?</b> {{ data.item.settings.allow_more_numbers_on_prize_draw ? 'Sim' : 'Não' }}<br>
        <b>Máximo de Números por participante:</b> {{ data.item.max_luck_numbers_participant }}
        <div v-if="data.item.settings.is_instagram_promotion">
          <b-badge variant="primary">
            Promoção para Instagram
          </b-badge>
        </div>
      </template>
      <template
        #cell(action)="data"
      >
        <b-button-group justify>
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            :disabled="data.item.is_archived"
            @click="setCurrentPromotion(data.item)"
          />
          <i2-icon-button
            icon="PlusCircleIcon"
            variant="outline-secondary"
            :disabled="data.item.is_archived"
            @click="onDetails(data.item)"
          />
          <i2-icon-button
            v-if="userData.role === 'super_admin'"
            icon="ArchiveIcon"
            variant="outline-danger"
            :disabled="data.item.is_archived"
            @click="confirmArchivePromotionModal(data.item)"
          />
        </b-button-group>
      </template>
    </Grid>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import userType from '@/variables/userTypes'
import PromotionForm from '@/views/promotions/PromotionForm.vue'
import PromotionArchiveConfirmationModal from '@/components/app/promotion/PromotionArchiveConfirmationModal.vue'
import { mapActions } from 'vuex'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    PromotionArchiveConfirmationModal,
    PromotionForm,
    Grid,
    BCardCode,
  },
  data() {
    return {
      fields: [
        {
          key: 'information',
          label: 'Informações',
          class: 'w-40',
        },
        {
          key: 'configuration',
          label: 'Configurações',
          class: 'w-35',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10 text-center',
        },
      ],
      items: [],
      currentPromotion: {},
      isActivated: false,
      userType,
      currentPromotionArchive: {},
      openArchivePromotionModal: false,
      userData: getUserData(),
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.currentPromotion = {}
      }
    },
  },
  mounted() {
    this.getPromotions()
  },
  methods: {
    async getPromotions() {
      const response = await this.$http.get('promotions')
      this.items = response || []
    },
    setCurrentPromotion(promotion) {
      this.currentPromotion = { ...promotion }
      this.isActivated = true
    },
    newPromotion() {
      this.currentPromotion = {}
      this.isActivated = true
    },
    onPromotionSave() {
      this.currentPromotion = {}
      this.isActivated = false
      this.getPromotions()
    },
    onCancelEdit() {
      this.isActivated = false
    },
    onDetails(item) {
      this.$router.push(`/promotions/${item.uuid}/details`)
      this.isActivated = false
    },
    confirmArchivePromotionModal(promotion) {
      this.currentPromotionArchive = promotion
      this.openArchivePromotionModal = true
    },
    closeArchivePromotionModal() {
      this.currentPromotionArchive = {}
      this.openArchivePromotionModal = false
      this.getPromotions()
    },
  },
}
</script>
<style>

</style>
